import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import moment from "moment";
import theme from "../../styles/theme";
import numberHelpers from '../../helpers/number';
import { textAlign, width } from "styled-system";

Font.register({
  family: "Manrope",
  fonts: [
    {
      src: "/assets/fonts/Manrope-Regular.ttf",
      fontStyle: "normal",
      fontWeight: 400,
      format: "truetype",
    },
    {
      src: "/assets/fonts/Manrope-SemiBold.ttf",
      fontStyle: "normal",
      fontWeight: 600,
      format: "truetype",
    },
  ]
});

const Logo = "/assets/images/logo.png";


const ExtractPDF = ({ pdfData,translate }) => {

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }

  const handleDescription = (transaction) => {
    let description = transaction.description;

    if(transaction && transaction.comment && (transaction.historyCode === 1201 || transaction.historyCode === 1301)){
      description = transaction.comment;
      if(transaction.comment.customDescription){
          description = transaction.comment.customDescription;
      }
    }
  
    return description;
  }

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <View style={styles.header}>
          <View>
            <Text style={styles.title}>
              Extrato da conta - Superbid Pay
            </Text>
            <View style={styles.subtitle}>
              <Text style={styles.subtitleDescription}>
                Período:
              </Text>
              <Text style={styles.subtitleYear}>
                {" "}{moment(pdfData.startDate).format('DD/MM/YYYY')}
                {" até "}{moment(pdfData.endDate).format('DD/MM/YYYY')}
              </Text>
            </View>
          </View>

          <Image
            src={`${Logo}`}
            style={styles.logo}
          />
        </View>
        <View style={styles.content}>
          <View style={styles.divider} />
          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("recipient.client")}:
            </Text>
            <Text style={styles.infoValue}>{pdfData.name}</Text>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("recipient.document")}:
            </Text>
            <Text style={styles.infoValue}>{numberHelpers.documentMask(pdfData.document)}</Text>
          </View>

          {pdfData.agency && pdfData.account && (
            <View style={styles.infoRow}>
              <View style={styles.infoContainer}>
                <Text style={styles.infoTitle}>
                  {translate("recipient.agency")}:
                </Text>
                <Text style={styles.infoValue}>{pdfData.agency}</Text>
              </View>

              <View style={styles.infoContainer}>
                <Text style={styles.infoTitle}>
                  {translate("recipient.account")}:
                </Text>
                <Text style={styles.infoValue}>{pdfData.account}</Text>
              </View>
            </View>
          )}
        </View>
        <View style={styles.content}>
          <View style={styles.divider} />
          {pdfData.extractList.length === 0 ? (
            <View>
              <Text style={styles.disclaimer}>Não foram encontradas transações no período selecionado</Text>
            </View>
              )
                  : (
                    <View>
                      <Text style={styles.transaction}>Transações</Text>
                      <View style={styles.tableHeader}>
                          <Text style={styles.tableTextOne}>Data</Text>
                          <Text style={styles.tableTextTwo}>Descrição</Text>
                          <Text style={styles.tableValueBlock}>Valor</Text>
                          <Text style={styles.tableValueBlock}>Comprador/Vendedor</Text>
                          <Text style={styles.tableValueBlock}>Documento</Text>
                      </View>
                    </View>
              )}
          {pdfData.extractList.length > 0 && 
                  pdfData.extractList.map((transaction, index) => {
                    return(
                      <View
                        key={index}
                        style={[styles.tableValue, index % 2 === 0 && { backgroundColor: '#F9F1FF'} ]}
                      >
                          <Text style={[styles.tableTextOne, styles.tableValueText]}>
                            {moment(transaction.creditDate).format("DD/MM/YYYY")}
                          </Text>
                          <Text style={[styles.tableTextTwo, styles.tableValueText]}>
                            {handleDescription(transaction)}
                          </Text>
                        <View style={[styles.tableValueBlock, styles.tableValueText]}>
                          <Text style={transaction.type === 'C' ? {color: '#59AC7C'} : 
                          transaction.type === 'D' ? {color: '#BD3939'} : {color: '#66696F'}}
                          >
                            {  transaction.type === 'D' ? `- ${formatCurrency(transaction.amount)}` : formatCurrency(transaction.amount)}
                          </Text>
                        </View>
                        <View style={styles.tableValueBlock}>
                            <Text style={styles.tableValueText}>
                                  {transaction.counterpart && transaction.counterpart.name}
                            </Text>
                        </View>
                        <View style={styles.tableValueBlock}>
                            <Text style={styles.tableValueText}>
                                  {transaction.counterpart && transaction.counterpart.taxIdentifier && transaction.counterpart.taxIdentifier.taxId}
                            </Text>
                        </View>
                      </View>
                    )
                  })
                }
        </View>





        <View style={styles.footer}>
          <View style={styles.divider} />
          <Text style={styles.text}>
            2024 Superbid © • Rua Henrique Monteiro, nº 125, 2º andar - Pinheiros - São Paulo - SP 
            Atendimento de segunda à sexta das 9:00 às 18:00, exceto feriados : (11) 4950 9400
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    borderTopWidth: 3,
    borderTopColor: theme.colors.brand.primary,
    paddingTop: 28,
    paddingHorizontal: 40
  },
  logo: {
    width: "118px",
    height: "36px",
  },
  title: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 20,
    color: theme.colors.text.medium,
  },
  subtitle: {
    fontFamily: "Manrope",
    fontWeight: 400,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15
  },
  subtitleDescription: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.text.medium,
  },
  subtitleYear: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.functional.primary[700],
  },
  content: {
    paddingHorizontal: 40
  },
  contentTitle: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 16,
    color: theme.colors.functional.primary[900]
  },
  divider: {
    width: "100%",
    backgroundColor: theme.colors.text.lightest,
    height: "1px",
    marginBottom: 15
  },
  infoContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: 8
  },
  infoTitle: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 12,
    color: theme.colors.text.regular
  },
  infoValue: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 12,
    color: theme.colors.text.medium,
    marginLeft: 4
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 30
  },
  footer: {
    flex: 1,
    justifyContent: "flex-end",
    paddingHorizontal: 40,
    marginBottom: 16
  },
  text: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 12,
    color: theme.colors.text.medium,
    textAlign: "center",
    marginTop: 8
  },
  transaction: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 18,
    color: theme.colors.text.medium,
    marginBottom: 30
  },
  disclaimer: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.text.medium,
    marginBottom: 30
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: theme.colors.brand.primary,
    padding: 2,
    color: "#FFF",
    textAlign: 'center',
    width: '100%',
    fontSize: 12
  },
  tableBlock: {
    flexDirection: 'row',
    width: '100%'
  },
  tableValueBlock: {
    width: '20%',
  },
  
  tableTextOne: {
    width: '15%'
  },
  tableTextTwo: {
    width: '20%'
  },
  tableValue: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#FFF',
    textAlign: 'center',
    padding: 2,
    color: "#66696F"
  },

  tableValueText : {
    fontSize: 10
  }


});

export default ExtractPDF;